<template>
  <div class="">
    <v-row>
      <v-col><v-btn class="primary my-2" :to="{ name: 'application_tab', params: { id: regId } }">Back</v-btn></v-col>
      <v-spacer></v-spacer>
      <v-col class="text-right">
        <sidebar-profile v-bind:registration-id="regId"></sidebar-profile>
        <!-- <SidebarProfile :registration-id="regId" /> -->
      </v-col>
    </v-row>
    <!-- <v-btn
      class="primary ma-1"
      :to="{ name: 'application_tab', params: { id: regId } }"
      >Back</v-btn
    > -->

    <v-stepper v-model="el" class="vstepper">
      <v-layout justify-center>
        <v-flex xs2>
          <v-stepper-header class="stepperHeader">
            <template v-for="n in steps" class="stepperHeaderTemplate">
              <v-card :key="n.sn" class="sober elevation-12 pt-1 pb-1">
                <v-stepper-step :key="n.sn" :step="n.sn" editable complete class="vstepperStep">
                  {{ n.text }}
                </v-stepper-step>
              </v-card>
            </template>
          </v-stepper-header>
        </v-flex>
        <v-flex xs10>
          <v-card>
            <v-stepper-items>
              <v-stepper-content v-for="n in steps" :key="`${n.sn}-content`" :step="n.sn" vertical>
                <component class="sober" :isPostAuthorized="isPostAuthorized" :is="getComponent(n.sn)" v-if="n.sn === el" v-bind:next-step="nextStep"
                  v-bind:registration-id="regId" :id="id" />
              </v-stepper-content>
            </v-stepper-items>
          </v-card>
        </v-flex>
      </v-layout>
    </v-stepper>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="3000">
      {{ successfulMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import Application from "./components/Application.vue";
import CompoundWall from "./components/CompoundWall.vue";
import ApplicationRepeatablevtwo from "./components/ApplicationRepeatablevtwo.vue";
import ApplicationRepeatable from "./components/application-repeatable/ApplicationRepeatable.vue";
import Houseownerinfo from "./components/Houseownerinfo.vue";
import Designer from "./components/Designer.vue";
import Supervisor from "./components/Supervisor.vue";
import LandInfoAddress from "./components/LandInfoAddress.vue"
import Charkilla from "./components/Charkilla.vue";
import Landinfoarea from "./components/Landinfoarea.vue";
import Landowner from "./components/Landowner.vue";
import Contractor from "./components/Contractor.vue";
import Applicant from "./components/Applicant.vue";
import SidebarProfile from "../applicationlevel/components/SidebarProfile.vue";

// const COMPONENT_MAP = {
// 1: "CompoundWall",
// 2: "Houseownerinfo",
// 3: "Designer",
// 4: "Supervisor",
// 5: "LandInfoaddress",
// 6: "Charkilla",
// 7: "Landinfoarea",
// 8: "Landowner",
// 9: "Contractor",
// 10: "Applicant",
// };

export default {
  name: "TabbedElements",
  props: {
    id: {
      cast: Number,
    },
    regId: {
      cast: Number,
    },
    sn: {
      cast: Number,
    },
    isPostAuthorized: Boolean,
  },
  data() {
    return {
      el: Number(this.sn),
      userRoleId: localStorage.getItem("auth_user_role_id"),
      vertical: true,
      // : this.regId,
      // id: this.id,
      reg: [],
      steps: [
        { text: "निवेदन", sn: 1 },
        { text: "घरधनीको विवरण", sn: 2 },
        { text: "डिजाइनर", sn: 3 },
        { text: "सुपरभाइजर", sn: 4 },
        { text: "जग्गाको विवरण (ठेगाना)", sn: 5 },
        { text: "चारकिल्ला", sn: 6 },
        { text: "जग्गाको विवरण (क्षेत्रफल)", sn: 7 },
        { text: "जग्गाधनीको विवरण", sn: 8 },
        { text: "निर्माणकर्मी / निर्माण व्यवसायी", sn: 9 },
        { text: "निवेदकको विवरण", sn: 10 },
      ],
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
      COMPONENT_MAP: {
        1: "Application",
        2: "Houseownerinfo",
        3: "Designer",
        4: "Supervisor",
        5: "LandInfoAddress",
        6: "Charkilla",
        7: "Landinfoarea",
        8: "Landowner",
        9: "Contractor",
        10: "Applicant",
      },

      // el: 1,
      // steps: 10,
    };
  },
  components: {
    Application,
    ApplicationRepeatable,
    ApplicationRepeatablevtwo,
    Houseownerinfo,
    Designer,
    Supervisor,
    LandInfoAddress,
    Charkilla,
    Landinfoarea,
    Landowner,
    Contractor,
    Applicant,
    //  Mason,
    // Consultant,
    SidebarProfile,
    CompoundWall,
  },

  watch: {
    steps(val) {
      if (this.el > val) {
        this.el = val;
      }
    },
  },
  computed: {
    // steps: {
    //   get: function () {
    //     return [
    //       { text: "निवेदन", sn: 1 },
    //       { text: "घरधनीको विवरण", sn: 2 },
    //       { text: "डिजाइनर", sn: 3 },
    //       { text: "सुपरभाइजर", sn: 4 },
    //       { text: "जग्गाको विवरण (ठेगाना)", sn: 5 },
    //       { text: "चारकिल्ला", sn: 6 },
    //       { text: "जग्गाको विवरण (क्षेत्रफल)", sn: 7 },
    //       { text: "जग्गाधनीको विवरण", sn: 8 },
    //       { text: "निर्माणकर्मी / ठेकेदार", sn: 9 },
    //       { text: "निवेदकको विवरण", sn: 10 },
    //     ];
    //   },
    // },
  },
  created() {
    this.getConstructionType();
  },
  methods: {
    getComponent(n) {
      return this.COMPONENT_MAP[n];
    },
    nextStep(n) {
      // debugger;
      if (n === this.steps.sn) {
        this.el = 1;
      } else {
        this.el += n;
      }
    },
    getNewComponentMap() {
      let regData = this.$store.getters.registrationData;
      // console.log(`regdata: ${regData.id}`);
      // Test if params.regid equal to regdata.id
      // if (regData.id != this.params.regId){
      //   this.$store.dispatch("getRegistration", parseInt(this.params.regId))
      // }
      if (regData.construction_type_id == 11) {
        this.COMPONENT_MAP[1] = "ApplicationRepeatablevtwo";
      } else if (regData.construction_type_id == 4) {
        this.COMPONENT_MAP[1] = "CompoundWall";
      }
    },
  },
  created() { },
  mounted() {
    this.getNewComponentMap();

    // The below commented line is most probably not needed.
    // const urlApp = `api/registration/${this.$route.params.regId}`;
    // this.$axios.get(urlApp).then((response) => {
    //   this.reg = response.data.data;
    // });
    // console.log(`component map: ${COMPONENT_MAP}`);
  },
};
</script>
<style scoped>
.vstepperStep {
  width: 300px;
  padding: 15px;
}

.vstepper {
  min-height: 100vh;
}

.stepperHeaderTemplate .stepperHeader {
  height: 10px;
  width: 300px;
}

.v-stepper__header {
  height: 0px !important;
}
</style>
