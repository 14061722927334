import { render, staticRenderFns } from "./FifteenDaysBibaranTabbed.vue?vue&type=template&id=52878326&scoped=true&"
import script from "./FifteenDaysBibaranTabbed.vue?vue&type=script&lang=js&"
export * from "./FifteenDaysBibaranTabbed.vue?vue&type=script&lang=js&"
import style0 from "./FifteenDaysBibaranTabbed.vue?vue&type=style&index=0&id=52878326&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52878326",
  null
  
)

export default component.exports