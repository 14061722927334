<template>
  <div class="vld-parent">
    <!-- Change can-cancel to false later -->
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    <v-card class="sober mb-8 elevation-0">
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title v-if="$store.getters.registrationData.construction_type_id != 11"> निवेदन </v-toolbar-title>
        <v-toolbar-title v-else>Block {{ applicationIndex }}</v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" class="mx-4" v-model="valid">
        <v-row class="mt-2">
          <v-col cols="6" md="3">
            <label> निवेदन मिति(वि.स.)<span> *</span></label>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="application.application_date_np"
              :rules="requiredFormat('निवेदन मिति(वि.स.)', `${this.$regexList.nepDate}`)"
              label="निवेदन मिति(वि.स.)"
              class=""
              clearable
              ref="dateField"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="3">
            <label>भू-उपयोग क्षेत्र<span> *</span></label>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="application.land_use_area_id"
              :rules="[required('भू-उपयोग क्षेत्र')]"
              :items="landUseArea"
              item-value="id"
              item-text="name"
              :key="id"
              dense
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" md="3">
            <label> भवनको प्रयोजन<span> *</span></label>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="application.building_use_id"
              :rules="[required('Building Use')]"
              :items="buildingUseList"
              item-value="id"
              item-text="building_use_type"
              :key="id"
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="3">
            <label> भवन ऐन अनुसार भवनको वर्गिकरण<span> *</span></label>
          </v-col>

          <v-col md="4">
            <!-- <v-text-field v-model="application.building_category_id" label=""      ></v-text-field>
                                 -->
            <v-select
              required
              v-model="application.building_category_id"
              :rules="[required('Building Category')]"
              :items="buildingCategory"
              item-value="id"
              item-text="building_category_type"
              :key="id"
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="3">
            <label> भवनको संरचना प्रणाली<span> *</span></label>
          </v-col>

          <v-col md="4">
            <v-select
              required
              v-model="application.building_structure_id"
              :rules="[required('Building Structure')]"
              :items="buildingStructure"
              item-value="id"
              item-text="building_structure_type"
              :key="id"
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" md="3">
            <label> भवनको लम्बाइ<span> *</span></label>
          </v-col>

          <v-col md="2">
            <v-text-field
              required
              v-model="application.building_length_ft"
              :rules="requiredFormat('Length (ft)', '^(?:[0-9]+|[०-९]+)$')"
              label="feet"
              dense
            ></v-text-field>
          </v-col>
          <v-col md="2">
            <v-text-field
              required
              v-model="application.building_length_in"
              :rules="requiredFormat('Length (in)', '^(?:[0-9]+|[०-९]+)$')"
              label="inch"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" md="3">
            <label> भवनको चौडाई<span> *</span></label>
          </v-col>

          <v-col md="2">
            <v-text-field
              required
              v-model="application.building_breadth_ft"
              :rules="requiredFormat('Breadth (ft)', '^(?:[0-9]+|[०-९]+)$')"
              label="feet"
              dense
            ></v-text-field>
          </v-col>
          <v-col md="2">
            <v-text-field
              required
              v-model="application.building_breadth_in"
              :rules="requiredFormat('Breadth(in)', '^(?:[0-9]+|[०-९]+)$')"
              label="inch"
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" md="3">
            <label> भवनको तला<span> *</span></label>
          </v-col>

          <v-col cols="6" md="2">
            <v-text-field
              v-model="application.building_storey"
              :rules="requiredFormat('Storey', '^(?:[0-9]+|[०-९]+)$')"
              label="भवनको तला"
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="2">
            <v-text-field v-model="application.building_storey_basement" label="बेसमेन्टको तला सङ्ख्या"></v-text-field>
          </v-col>
        </v-row>
        <v-row v-for="i in floorLengthBase" :key="i">
          <v-col cols="3" md="3"></v-col>
          <v-col cols="3" md="1">
            <v-text-field readonly v-model="floorRevenueBase[i - 1].floor_name"></v-text-field>
          </v-col>
          <v-col cols="2" v-if="hasHistory">
            <v-text-field label="Previous Area" type="number" v-model="floorRevenueBase[i - 1].prev_area"> </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field label="Area" type="number" v-model="floorRevenueBase[i - 1].area"></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Height (ft)" type="number" v-model="floorRevenueBase[i - 1].height_ft"></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Height (in)" type="number" v-model="floorRevenueBase[i - 1].height_in"></v-text-field>
          </v-col>
        </v-row>

        <v-row v-for="i in floorLength" :key="i">
          <v-col cols="3" md="3"></v-col>
          <v-col cols="3" md="1">
            <v-text-field readonly v-model="floorRevenueSurface[i - 1].floor_name"></v-text-field>
          </v-col>
          <v-col cols="2" v-if="hasHistory">
            <v-text-field label="Previous Area" type="number" v-model="floorRevenueSurface[i - 1].prev_area"> </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field label="Area" type="number" v-model="floorRevenueSurface[i - 1].area"></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Height (ft)" type="number" v-model="floorRevenueSurface[i - 1].height_ft"> </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="Height (in)" type="number" v-model="floorRevenueSurface[i - 1].height_in"> </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="3">
            <label> भुइँ तलाको क्षेत्रफल<span> *</span></label>
          </v-col>
          <!-- <v-text-field v-model="application.reg_id" :value="applicationrole.id" type="hidden" ></v-text-field> -->
          <v-col cols="12" md="4">
            <v-text-field
              required
              v-model="application.building_plinth_area"
              :rules="requiredFormat('Plinth Area', `${this.$regexList.area}`)"
              label="Plinth Area (sq.ft)"
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="3">
            <label> कुल भवन क्षेत्रफल<span> *</span></label>
          </v-col>
          <!-- <v-text-field v-model="application.reg_id" :value="applicationrole.id" type="hidden" ></v-text-field> -->
          <v-col cols="12" md="4">
            <v-text-field
              required
              v-model="application.building_max_area"
              :rules="requiredFormat('Total Area', `${this.$regexList.area}`)"
              label="Total Area (sq.ft)"
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" md="3">
            <label> भवनको कुल उचाइ<span> *</span></label>
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field
              required
              v-model="application.building_height_ft"
              :rules="requiredFormat('Height (ft)', `${this.$regexList.length}`)"
              label="feet"
              suffix="ft"
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              required
              v-model="application.building_height_in"
              :rules="requiredFormat('Height (in)', `${this.$regexList.length}`)"
              label="inch"
              suffix="in"
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn primary v-on:click="save(false)" v-if="isPostAuthorized" class="primary" :disabled="!valid">Save </v-btn>
            <v-btn primary v-on:click="save(true)" v-if="isPostAuthorized" class="primary ml-1" :disabled="!valid">Save &amp; Next</v-btn>
            <v-btn class="ma-1" color="primary" v-on:click="nextStep(1)"> Skip </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <br /><br />
      <!-- <div>
        <v-card class="sober mb-8 elevation-0">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>History</v-toolbar-title>
          </v-toolbar>
        </v-card>

      </div> -->
      <!-- <form-history
        @getOldData="getOldData($event)"
        :historyData="historyData"
        :sameAsOldData="same_as_old_data"
      ></form-history> -->
      <form-history :historyData="historyData"></form-history>

      <!-- Floor Info Revenues History -->
      <div v-if="hasHistory">
        <h4>Floor Info History</h4>
        <v-simple-table>
          <tr>
            <th>तला</th>
            <th>क्षेत्रफल</th>
            <th>उचाइ</th>
          </tr>
          <tr v-for="i in floorInfoRevenueHistory" :key="i">
            <td width="10%">{{ i.floor_name }}</td>
            <td width="10%">{{ i.area }}</td>
            <td width="10%">{{ i.height_ft }}'{{ i.height_in }}"</td>
          </tr>
        </v-simple-table>
        <!-- <div v-for="i in floorInfoRevenueHistory" :key="i"></div> -->
      </div>

      <v-snackbar
        v-model="snackbar.show"
        :bottom="y === 'bottom'"
        :color="snackbar.color"
        :left="x === 'left'"
        :multi-line="mode === 'multi-line'"
        :right="x === 'right'"
        :timeout="3000"
        :top="y === 'top'"
        :vertical="mode === 'vertical'"
      >
        {{ successfulMessage }}
      </v-snackbar>
      <!-- </v-card> -->
      <!-- </v-app> -->
    </v-card>
  </div>
</template>

<script>
import * as nepaliDatePicker from "../../../../node_modules/nepali-date-picker/dist/jquery.nepaliDatePicker.min.js"; /*eslint-disable-line*/
import "../../../../node_modules/nepali-date-picker/dist/nepaliDatePicker.min.css";
import { engNumConvert } from "../../mixins/numericToUnicode.js";
import SidebarProfile from "../../applicationlevel/components/SidebarProfile.vue";
import FormHistory from "../../history/FormHistory.vue";

export default {
  name: "Application",
  mixins: [engNumConvert],
  components: {
    // SidebarProfile,
    FormHistory,
  },
  props: {
    nextStep: {
      type: Function,
    },
    registrationId: {
      type: [String, Number],
    },
    id: {
      type: [String, Number],
    },
    // isPostAuthorized: {
    //   type: Boolean,
    // },
    regConstructionId: {
      type: Number,
    },
    applicationIndex: [String, Number],
    applicationId: [String, Number],
  },
  data() {
    return {
      valid: false,
      historyData: {
        apiUrl: `api/registration/${this.registrationId}/application/history`,
        formData: [
          {
            title: "भू-उपयोग क्षेत्र",
            key: "land_use_area",
          },
          {
            title: "भवनको प्रयोजन",
            key: "building_use",
          },
          {
            title: "भवन ऐन अनुसार भवनको वर्गिकरण",
            key: "building_category",
          },
          {
            title: "भवनको संरचना प्रणाली",
            key: "building_structure",
          },
          {
            title: "भवनको तला",
            key: "building_storey",
          },
          {
            title: "भुइँ तलाको क्षेत्रफल",
            key: "building_plinth_area",
          },
          {
            title: "कुल भवन क्षेत्रफल",
            key: "building_max_area",
          },
          {
            title: "भवनको कुल उचाइ",
            key: "building_height_ft",
          },
        ],
      },
      required(propertyType) {
        return (v) => !!v || `${propertyType} is required`;
      },
      requiredUnicode(propertyType) {
        return [
          (v) => !!v || `${propertyType} is required`,
          // v => /^\W$/.test(v) || 'nice one'
          (v) => /^[\W]+$/.test(v) || `${propertyType} नेपाली युनिकोडमा लेख्नुहोला ।`,
        ];
      },
      requiredFormat(propertyType, regexexp) {
        var regex = new RegExp(regexexp, "g");
        return [
          (v) => !!v || `${propertyType} is required`,
          (v) => regex.test(v) || `${propertyType}को फर्मेट मिलेन ।`,
          // /^२[०-९]{3}-[०१]?[०-९]-[०-३]?[०-९]$/   ‍=> Date
        ];
      },
      isFormSubmitting: true,
      date: "",
      isValid: true,
      userRoleId: localStorage.getItem("auth_user_role_id"),
      buildingUse: [],
      buildingCategory: [],
      buildingStructure: [],
      application: {
        id: 0,
        // reg_id: 0,
        application_date: "2020-04-22",
        application_date_np: "",
        building_category_id: "",
        land_use_area_id: "",
        building_use_id: "",
        building_structure_id: "",
        building_length_in: "",
        building_breadth_in: "",
        building_height_in: "",
        building_length_ft: "",
        building_breadth_ft: "",
        building_height_ft: "",
        building_storey: "",
        building_storey_basement: "",
        building_max_area: "",
        building_plinth_area: "",
        block: null,
      },
      floorRevenue: [],
      // floorRevenueCopy: [],
      floorRevenueBase: [],
      floorRevenueSurface: [],
      // floorInfo: {},
      // floorLength: null,

      floorName: [],

      applicationrole: [],
      // applicationList: [],
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },

      // land use area array
      landUseArea: [],
      landBuilding: [],
      // buildingUseList: [],
      isPostAuthorized: false,
      // hasHistory: false,
      floorInfoRevenueHistory: [],
      historyFloorRevenueSurface: [],
      historyFloorRevenueBase: [],
    };
  },
  computed: {
    hasHistory() {
      if (this.floorInfoRevenueHistory.length || this.$store.getters.registrationData.construction_type_id == 5) {
        return true;
      } else {
        return false;
      }
    },
    isLoading() {
      if (
        this.buildingUse.length === 0 ||
        this.buildingCategory.length === 0 ||
        this.buildingStructure.length === 0 ||
        this.isFormSubmitting === false
      ) {
        // all drop-down values not initiated from api
        return true;
      }
      return false;
    },
    floorLength() {
      return this.floorRevenueSurface.length;
    },
    floorLengthBase() {
      return this.floorRevenueBase.length;
    },
    baseArea() {
      let tempBaseArea = 0;
      this.floorRevenueSurface.forEach((element) => {
        element.floor == 0 ? (tempBaseArea = parseFloat(element.area == "" || element.area == null ? 0 : element.area)) : 0;
      });
      return tempBaseArea;
    },
    totalArea() {
      let tempTotalArea = 0;
      let tempFloorInfo = [];
      tempFloorInfo = tempFloorInfo.concat(this.floorRevenueSurface, this.floorRevenueBase);
      tempFloorInfo.forEach((element) => {
        tempTotalArea = tempTotalArea + parseFloat(element.area == "" || element.area == null ? 0 : element.area);
      });
      // this.application.building_max_area = tempTotalArea;
      return tempTotalArea;
    },
    totalHeight() {
      let tFeet = 0;
      let tInch = 0;
      let tQuotient = 0;
      let tRem = 0;
      this.floorRevenueSurface.forEach((element) => {
        tInch = tInch + parseFloat(element.height_in == "" || element.height_in == null ? 0 : element.height_in);

        tFeet = tFeet + parseFloat(element.height_ft == "" || element.height_ft == null ? 0 : element.height_ft);
      });
      if (tInch >= 12) {
        tQuotient = Math.floor(tInch / 12);
        tRem = tInch % 12; //Remove tRem Variable later
        tInch = tRem;
        tFeet = tFeet + tQuotient;
      }
      // default 0 is not approved from backend,so doing this for temporary,fix it later, 18th Nov
      if (!tInch) {
        tInch = "0";
      }
      if (!tFeet) {
        tFeet = "0";
      }
      return [tFeet, tInch];
    },
    buildingUseList() {
      // To dynamically load भवनको प्रयोजन (building_use) in dynamic dropdown
      let buildingUseListTemp = [];
      if (this.landBuilding.length) {
        for (let i = 0; i < this.landBuilding.length; i++) {
          if (this.application.land_use_area_id == this.landBuilding[i].land_use_area_id) {
            buildingUseListTemp.push(this.landBuilding[i].building_use);
          }
        }
      } else {
        buildingUseListTemp = this.buildingUse;
      }
      return buildingUseListTemp;
    },
  },
  watch: {
    "application.building_storey": function (val) {
      val = this.nepNumToEng(val);
      this.floorRevenueSurface.splice(0);
      this.getRevenueSurface();
      this.floorRevenueSurface.splice(val);
      // console.log(`floorRlen:${this.floorRevenue.length}`);
      for (let i = this.floorRevenueSurface.length; i < val; i++) {
        this.floorRevenueSurface.push({
          id: 0,
          floor: i,
          // prev_area: this.historyFloorRevenueSurface[i].area,
          application_id: 0,
          block: this.applicationIndex, // New
          prev_area: "",
          area: "",
          height_ft: "",
          height_in: "",
          floor_name: this.floorName.filter((obj) => {
            return obj.floor_id === i.toString();
          })[0].name,
          // floor_name: "oo"
        });
      }
    },
    "application.building_storey_basement": function (val) {
      val = this.nepNumToEng(val);
      this.floorRevenueBase.splice(0);
      this.historyFloorRevenueBase.splice(0);
      this.getRevenueBase();
      this.floorRevenueBase.splice(val);
      // console.log(`floorRlen:${this.floorRevenue.length}`);
      for (let k = this.floorRevenueBase.length; k < val; k++) {
        this.floorRevenueBase.push({
          id: 0,
          floor: -(k + 1),
          // prev_area: this.historyFloorRevenueBase[i].area,
          application_id: 0,
          block: this.applicationIndex, // New
          prev_area: "",
          area: "",
          height_ft: "",
          height_in: "",
          floor_name: this.floorName.filter((obj) => {
            return obj.floor_id === (-k - 1).toString();
          })[0].name,
        });
      }
    },
    baseArea: function () {
      this.application.building_plinth_area = this.baseArea;
    },
    totalArea: function () {
      this.application.building_max_area = this.totalArea.toFixed(2);
    },
    totalHeight: function () {
      this.application.building_height_ft = this.totalHeight[0];
      this.application.building_height_in = this.totalHeight[1];
    },
  },
  created() {
    // this.buildingUseCall();  // भवनको प्रयोजन
    this.buildingCategoryCall();
    this.buildingStructureCall();
    this.getLandBuilding(); // to get landbuiding pivot table
    // this.floorRevenueCall();
    // this.getFloorInfoRevenueHistory();
    this.getRole();
    this.getDate();
    this.getLandUseArea(); // to get land use area for भू-उपयोग क्षेत्र
    this.getNibedanAuthorization();
  },
  mounted() {
    this.applicationDetailCall();

    const { dateField } = this.$refs;
    if (dateField) {
      $(dateField.$el).nepaliDatePicker({
        dateFormat: "%y-%m-%d",
        closeOnDateSelect: true,
      });
      $(dateField.$el).on("dateSelect", (event) => {
        console.log(`Date: ${event.datePickerData.formattedDate}`);
        this.application.application_date_np = event.datePickerData.formattedDate;
      });
    }
  },
  methods: {
    // alertSuccess(message) {
    //   this.$swal("Added!", message, "success");
    // },
    // alertUpdate(message) {
    //   this.$swal("Updated!", message, "success");
    // },
    // alertDelete(message) {
    //   this.$swal("Deleted!", message, "success");
    // },
    alertError(message) {
      this.$swal.fire({
        icon: "error",
        title: message,
        text: "Something went wrong!",
      });
    },
    getOldData(oldData) {
      if (oldData.length) {
        this.hasHistory = true;
      }
    },
    getNibedanAuthorization() {
      this.isPostAuthorized = this.$store.getters.nibedanBibaranAuthData;
    },
    getLandBuilding() {
      // This api is only available for sidnagar,
      const apiUrl = `api/land-building`;
      this.$axios
        .get(apiUrl)
        .then((response) => {
          this.landBuilding = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getLandUseArea() {
      const apiUrl = `api/land-use-area`;
      this.$axios
        .get(apiUrl)
        .then((response) => {
          this.landUseArea = response.data.data;
          this.buildingUseCall();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // New #new

    getRevenueSurface() {
      this.floorRevenueSurface.splice(0);
      for (let j = 0; j < this.floorRevenue.length; j++) {
        if (parseInt(this.floorRevenue[j].floor) >= 0) {
          this.floorRevenueSurface.push(this.floorRevenue[j]);
          // New
          if (this.floorInfoRevenueHistory.length) {
            this.floorRevenueSurface.at(-1).prev_area = this.floorInfoRevenueHistory[j].area;
            this.floorRevenueSurface.at(-1).height_ft = this.floorInfoRevenueHistory[j].height_ft;
            this.floorRevenueSurface.at(-1).height_in = this.floorInfoRevenueHistory[j].height_in;
          }
        }
      }
      this.floorRevenueSurface.sort((a, b) => parseInt(a.floor) - parseInt(b.floor));
    },
    getRevenueBase() {
      this.floorRevenueBase.splice(0);
      for (let j = 0; j < this.floorRevenue.length; j++) {
        if (parseInt(this.floorRevenue[j].floor) < 0) {
          this.floorRevenueBase.push(this.floorRevenue[j]);
          // New
          // this.floorRevenueSurface.at(-1).prev_area = this.floorInfoRevenueHistory[j].area;
          if (this.floorInfoRevenueHistory.length) {
            this.floorRevenueBase.at(-1).prev_area = this.floorInfoRevenueHistory[j].area;
            this.floorRevenueBase.at(-1).height_ft = this.floorInfoRevenueHistory[j].height_ft;
            this.floorRevenueBase.at(-1).height_in = this.floorInfoRevenueHistory[j].height_in;
          }
        }
      }
      this.floorRevenueBase.sort((a, b) => parseInt(a.floor) - parseInt(b.floor));
      console.log(`revenue-base:${this.floorRevenueBase}`);
    },
    getDate() {
      const dateUrl = "api/current-date";
      this.$axios
        .get(dateUrl)
        .then((response) => {
          this.application.application_date_np = response.data.date_np;
          this.application.application_date = response.data.date;
        })
        .catch(() => {});
    },
    getRole() {
      const urlApp = `api/registration/${this.$route.params.regId}`;
      this.$axios.get(urlApp).then((response) => {
        this.applicationrole = response.data.data;
        console.log(this.applicationrole.phase_id);
        console.log(this.userRoleId);
        console.log(this.applicationrole.desk_user_role_id);
      });
    },
    applicationDetailCall() {
      const constructionTypeId = this.$store.getters.registrationData.construction_type_id;
      if (constructionTypeId == 11) {
        var url = `api/registration/${this.registrationId}/application/${this.applicationId}`;
      } else {
        var url = `api/registration/${this.registrationId}/application`;
      }
      this.$axios
        .get(url)
        .then((response) => {
          this.application = response.data.data;
          this.floorRevenue = response.data.data.floor_detail;
          // this.getFloorInfoRevenueHistory();

          // add building storey basement in db later
          // this.application.building_storey_basement = "2";
          // console.log(this.application.building_storey_basement);
        })
        .then(this.getRevenueSurface(), this.getRevenueBase(), this.getFloorNames())
        .catch((error) => {
          console.log(`api error:${error}`);
        });
    },
    getFloorNames() {
      const url = `api/floor-names`;
      this.$axios
        .get(url)
        .then((response) => {
          this.floorName = response.data.data;
        })
        .catch((error) => {
          console.log(`floor-master-api-error; ${error}`);
        });
    },
    buildingUseCall() {
      //भवनको प्रयोजन
      const buildingUseUrl = "api/building-use";
      this.$axios
        .get(buildingUseUrl)
        .then((response) => {
          this.buildingUse = response.data;
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });
    },
    buildingCategoryCall() {
      const buildingCategoryUrl = "api/building-category";
      this.$axios
        .get(buildingCategoryUrl)
        .then((response) => {
          this.buildingCategory = response.data;
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });
    },
    buildingStructureCall() {
      const buildingStructureUrl = "api/building-structure";
      this.$axios
        .get(buildingStructureUrl, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          this.buildingStructure = response.data;
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });
    },
    save(isNext) {
      // this.saveFloorInfo();
      let floorInfo = this.floorRevenueSurface;
      floorInfo = floorInfo.concat(this.floorRevenueBase);
      this.application.floor_detail = floorInfo;
      this.application.block = this.applicationIndex;

      this.isFormSubmitting = false;
      if (this.application.id === null || this.application.id === 0 || this.application.id === undefined) {
        const url = `/api/registration/${this.$route.params.regId}/application`;
        // console.log('this.application' + this.application)
        this.$axios
          .post(url, this.application)
          .then((response) => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.isFormSubmitting = true;
              this.application = response.data.data;
              this.floorRevenue = response.data.data.floor_detail;
              this.getRevenueSurface();
              this.getRevenueBase();
              this.snackbar.show = true;
              this.successfulMessage = "Successfully Added";
              // this.alertSuccess("Successfully Added !!");
              if (isNext) {
                this.nextStep(1);
              }
            }
          })
          .catch((error) => {
            this.isFormSubmitting = true;
            console.log(`api error:${error}`);
          });
      } else {
        this.application.block = this.applicationIndex;
        const eurl = `/api/registration/${this.$route.params.regId}/application/${this.application.id}`;
        this.$axios
          .put(eurl, this.application)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.isFormSubmitting = true;
              this.application = response.data.data;
              this.floorRevenue = response.data.data.floor_detail;
              this.getRevenueSurface();
              this.getRevenueBase();
              this.snackbar.show = true;
              this.successfulMessage = "Successfully Updated";
              if (isNext) {
                this.nextStep(1);
              }
            }
            console.log(response);
          })
          .catch((error) => {
            this.isFormSubmitting = true;
            console.log(error);
          });
      }
    },
    edit(id) {
      const url = `api/registration/${this.$route.params.regId}/application/${id}`;
      this.$axios.get(url).then((response) => {
        this.application = response.data.data;
      });
    },
    deleterecord(id) {
      const deleteurl = `api/registration/${this.$route.params.regId}/application/${id}`;
      this.$axios
        .delete(deleteurl, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.successfulMessage = "Successfully Deleted";
            // this.alertDelete("Successfully Deleted !!");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFloorInfoRevenueHistory() {
      // repsonse status 500 or 404 ko laagi milauna parne xa
      const apiUrl = `api/registration/${this.registrationId}/floor-info-revenue/history`;
      this.$axios
        .get(apiUrl)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.floorInfoRevenueHistory = response.data.data[0].floorRevenue;
            if (this.floorInfoRevenueHistory.length) {
              this.hasHistory = true;
            } else {
              this.hasHistory = false;
            }
            this.getRevenueSurface();
            this.getRevenueBase();
            this.getFloorNames();
          } else {
            this.getRevenueSurface();
            this.getRevenueBase();
            this.getFloorNames();
          }
        })
        .catch((error) => {
          console.log(error);
          this.hasHistory = false;
          this.getRevenueSurface();
          this.getRevenueBase();
          this.getFloorNames();
        });
    },
  },
};
</script>

<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}

.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}

.row,
.col {
  height: 58px;
}

.custom-text-field,
.v-text-field,
.v-text-field--enclosed,
.v-input__slot {
  padding: 0px;
}

/* .v-text-field__details {
margin: 0 !important;
padding: 5px !important;
} */
label span {
  color: red;
}

/* .v-text-field__details {
    position: absolute !important;
    margin: 12px 100px!important;
} */
.error-message {
  padding: 10px !important;
}
</style>
